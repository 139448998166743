import React from 'react';
import Footer1 from '../../components/Home/Footer1';
import Footer2 from '../../components/Home/Footer2';
import Footer3 from '../../components/Home/Footer3';

const Footer = () => (
  <div>
    <Footer1 />
    <Footer2 />
    <Footer3 />
  </div>
);

export default Footer;
