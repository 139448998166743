import React from 'react';

import { Container, Content } from '../../components/Home/homeStyledComponents';
import MenuBar from '../../components/Home/MenuBar';
import Footer from '../../components/Home/Footer';
import InsuranceBlock from '../../components/Insurance/InsuranceBlock';
import Layout from '../../layouts/index';

class Insurance extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="Insurance">
        <Container>
          <Content>
            <MenuBar />
            <InsuranceBlock />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default Insurance;
